import React, { useState, useRef } from 'react';

const Futer = () =>  {

return (
    <div className='futer-block'>
        <h3>© 2020—2024 ichor.by</h3>

    </div>
)
};

export default Futer;

